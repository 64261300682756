import { Column, Tag } from '@carbon/react';
import {
  IbmWatsonNaturalLanguageUnderstanding,
  IbmWatsonKnowledgeStudio,
  IbmWatsonAssistant,
  IbmWatsonDiscovery,
} from '@carbon/icons-react';
import './MvpBody.scss';

const Demos = (props: any) => {

  let Icon;
  switch (props.icon) {
    case 'IbmWatsonNaturalLanguageUnderstanding':
      Icon = IbmWatsonNaturalLanguageUnderstanding;
      break;
    case 'IbmWatsonKnowledgeStudio':
      Icon = IbmWatsonKnowledgeStudio;
      break;
    case 'IbmWatsonAssistant':
      Icon = IbmWatsonAssistant;
      break;
    case 'IbmWatsonDiscovery':
      Icon = IbmWatsonDiscovery;
      break;
    default:
      Icon = null;
  }

  return (
    <Column lg={4} md={3} sm={4}>
      <div className="grid-tile">
        {Icon && <Icon className="grid-icon" size={32} />}
        <h4>{props.title}</h4>
        <div className="grid-tag-wrapper">
          <Tag className="grid-tag" type="magenta">
            {props.tags1}
          </Tag>
          {props.tags.map((tag: any) => {
            return (
              <Tag className="grid-tag" type="blue" filter={false}>
                {tag}
              </Tag>
            );
          })}
        </div>
        <div className="tile-description">
          {props.description}
          <br />
          <br />
          {props.email}
          <br />
          {props.password}
        </div>
        <div className="grid-url">
          <a href={props.link} target="_blank" rel="noreferrer">
            {props.demo}
          </a>
        </div>
      </div>
    </Column>
  );
};

export default Demos;
