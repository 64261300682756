import './styles/App.scss';
import { useState, useEffect } from 'react';
import Layout from './lib/layouts/Layout';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { MvpRoute } from './lib/types/MvpRoute';
import Loginscreen from './pages/Login/Login';
import Startpage from './pages/StartPage/StartPage';
import { Theme } from '@carbon/react';

function ProtectedRoute({ element: Component, isProtected, ...rest }: any) {
  const [welcomeDisplayState, setWelcomeDisplayState] = useState(
    sessionStorage.getItem('welcomeDisplayState') === 'true',
  );

  // Check if the route is protected and if the welcome display state is true
  if (isProtected && !welcomeDisplayState) {
    return <Navigate to="/" />;
  }

  // Render the component if the route is not protected or if the welcome display state is true
  return (
    <Component
      {...rest}
      setWelcomeDisplayState={setWelcomeDisplayState}
      welcomeDisplayState={welcomeDisplayState}
    />
  );
}

function App() {
  const [currentTheme, setCurrentTheme] = useState('g90');

  // Set the initial value of welcomeDisplayState when the app loads
  const [welcomeDisplayState, setWelcomeDisplayState] = useState(() => { sessionStorage.getItem('welcomeDisplayState');});

 useEffect(() => {
   // Set a timeout to clear sessionStorage after 1 minute of inactivity
   let timeout = setTimeout(() => {
     sessionStorage.clear();
     window.location.href = '/';
   }, 30 * 60 * 1000);

   // Reset the timeout whenever there is an action or cursor movement
   window.addEventListener('mousemove', () => {
     clearTimeout(timeout);
     timeout = setTimeout(() => {
       sessionStorage.clear();
       window.location.href = '/';
     }, 30 * 60 * 1000);
   });

   return () => {
     window.removeEventListener('mousemove', () => {});
     clearTimeout(timeout);
   };
 }, []);


  // here you can setup your routes:
  const MvpRoutes: MvpRoute[] = [
    { element: Loginscreen, path: '/', isStartPage: true, isProtected: false },
    { element: Startpage, path: '/startpage', isStartPage: true, isProtected: true },
  ];

  // ------------------------------

  return (
    <BrowserRouter>
      <Theme className="themeWrapper" theme={currentTheme}>
        <Layout
          routes={MvpRoutes}
          setCurrentTheme={setCurrentTheme}
          appPrefix="IBM"
          appTitle="Justicia"
        >
          <Routes>
            {MvpRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      element={route.element}
                      isProtected={route.isProtected}
                      setWelcomeDisplayState={setWelcomeDisplayState}
                      welcomeDisplayState={welcomeDisplayState}
                    />
                  }
                />
              );
            })}
          </Routes>
        </Layout>
      </Theme>
    </BrowserRouter>
  );
}

export default App;
