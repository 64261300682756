import { Column, Grid, Tag, Search } from '@carbon/react';
import { Justice } from '@carbon/pictograms-react';
import '../../styles/Login_StartPage.scss';

import Demos from '../../lib/components/MvpBody/Demos';
import Kuerzeverfuegbar from '../../lib/components/MvpBody/ShortlyAvaiable';
import { TagFilter } from '../../lib/components/MvpBody/TagFilter';
import SearchHandler from '../../lib/components/MvpBody/Searchbar';

const StartPage = () => {
  const { tags_static, tags, addTag_deleteTag, matchTags, isActive } = TagFilter();
  const { searchData, searchData2, searchItem, searchItem2 } = SearchHandler();

  return (
    <div className="grid-area">
      <Grid>
        <Column lg={2} md={1} sm={0}></Column>
        <Column lg={12} md={6} sm={4}>
          <div className="header">
            <Grid>
              <Column lg={10} md={4} sm={4}>
                <div className="willkommen">
                  Willkommen auf <strong>IBM Justicia</strong>.<br />
                  Unsere Demo-Showcases
                  <br />
                  für die Justiz.
                </div>
              </Column>
              <Column lg={2} md={2} sm={0}>
                <div className="justice-icon-wrapper">
                  <Justice className="justice-icon" />
                </div>
              </Column>
            </Grid>
          </div>
          <div className="entdecken">
            Entdecken und erleben Sie unsere intelligenten Lösungen
            <br />
            für den deutschsprachigen Justiz-Bereich.
          </div>
          <Column lg={2} md={2} sm={0}></Column>
          <div className="searchbar">
            <Search
              onChange={(e: any) => {
                searchItem2(e.target.value);
                searchItem(e.target.value);
              }}
              placeholder="Suche und filtere nach Produkten und Themen."
            ></Search>
          </div>
          <div className="tag">
            <br />
            {tags_static.map((tag: any, color: any) => {
              return (
                <Tag
                  type={isActive[color] ? 'blue' : 'gray'}
                  className="grid-tag"
                  onClick={addTag_deleteTag(tag, color)}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
          <div className="demos">Unsere aktuellen Demos</div>
          <Grid id="start-page">
            {searchData
              .filter((proj: any) => matchTags(proj.tags, tags))
              .map((demos: any) => {
                return <Demos {...demos} key={demos.title} />;
              })}
          </Grid>
          <div className="kuerze">In Kürze verfügbar</div>
          <Grid>
            {searchData2.map((kuerzeverfuegbar: any) => {
              return <Kuerzeverfuegbar {...kuerzeverfuegbar} key={kuerzeverfuegbar.title} />;
            })}
          </Grid>
        </Column>
        <Column lg={2} md={1} sm={0}></Column>
      </Grid>
    </div>
  );
};

export default StartPage;