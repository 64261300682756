import i18n from "i18next";
import { initReactI18next } from "react-i18next";

var english = require('./languages/english.json');
var german = require('./languages/german.json');

const resources = {
    en: english,
    de: german
};

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        resources,
        lng: "en"
    });

export default i18n;
