import { Column} from '@carbon/react';
import './MvpBody.scss';

const Kuerzeverfuegbar = (props: any) => {
  return (
    <Column lg={4} md={3} sm={4}>
      <div className="grid-tile">
        <div className="tile-description">
          <h4>{props.title}</h4>
          <br />
          {props.description}
        </div>
      </div>
    </Column>
  );
};
export default Kuerzeverfuegbar;
