import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Column, Grid } from '@carbon/react';
import { Justice } from '@carbon/pictograms-react';
import { Login } from '@carbon/icons-react';
import '../../styles/Login_StartPage.scss';
import AppID from 'ibmcloud-appid-js';
import config from '../../lib/data/config.json';


const Loginscreen = ({ setWelcomeDisplayState }) => {
  const appID = React.useMemo(() => {
    return new AppID();
  }, []);

  // eslint-disable-next-line
  const [errorState, setErrorState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  (async () => {
    try {
      await appID.init(config);
    } catch (e: any) {
      setErrorState(true);
      setErrorMessage(e.message);
    }
  })();

  const [loginButtonDisplayState, setLoginButtonDisplayState] = React.useState(true);
  // eslint-disable-next-line
  const [userName, setUserName] = React.useState('');
  const navigate = useNavigate();

  const loginAction = async () => {
    try {
      const tokens = await appID.signin();
      setErrorState(false);
      setLoginButtonDisplayState(false);
      setWelcomeDisplayState(true);
      sessionStorage.setItem('welcomeDisplayState', 'true');
      navigate('/startpage');
      setUserName(tokens.idTokenPayload.name);
    } catch (e: any) {
      setErrorState(true);
      setErrorMessage(e.message);
    }
  };
  return (
    <>
      {loginButtonDisplayState && (
        <div className="grid-area">
          <div className="container">
            <Grid>
              <Column lg={2} md={1} sm={0}></Column>
              <Column lg={12} md={6} sm={4}>
                <div className="header">
                  <Grid>
                    <Column lg={10} md={4} sm={4}>
                      <div className="willkommen">
                        Willkommen auf <strong>IBM Justicia</strong>.<br />
                        Unsere Demo-Showcases
                        <br />
                        für die Justiz.
                      </div>
                    </Column>
                    <Column lg={2} md={2} sm={0}>
                      <div className="justice-icon-wrapper">
                        <Justice className="justice-icon" />
                      </div>
                    </Column>
                  </Grid>
                </div>
                <div className="entdecken">
                  Entdecken und erleben Sie unsere intelligenten Lösungen
                  <br />
                  für den deutschsprachigen Justiz-Bereich.
                </div>
                <Column lg={2} md={2} sm={0}></Column>
                <div>
                  <Button
                    id="login"
                    className="button_login"
                    onClick={loginAction}
                    renderIcon={Login}
                  >
                    Login
                  </Button>
                  {errorMessage && <div className="error">{errorMessage}</div>}
                </div>
              </Column>
              <Column lg={2} md={1} sm={0}></Column>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Loginscreen;
