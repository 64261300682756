import { useState, useCallback } from 'react';

export const TagFilter = () => {

  const tags_static = [
    'Watson Assistant',
    'Watson Discovery',
    'Watson Knowledge Studio',
    'Watson Natural Language Understanding',
  ];

  const [tags, setTags]: any[] = useState([]);

  const addTag_deleteTag = useCallback(
    (tag: any, color: any) => () => {
      if (!tags.includes(tag)) {
        return (setTags([...tags, tag]), handleClick(color));
      } else {
        const tagsFiltered = tags.filter((tagId: any) => {
          return tag !== tagId;
        });
        setTags(tagsFiltered);
        handleClick(color);
      }
    },
    [tags],
  );

  //Check Tags in the Array if already exist
  const matchTags = (current: any, target: any) => {
    return target.every((tag: any) => current.includes(tag));
  };


  //Color Change
  const [isActive, setIsActive]: any = useState(true);

  const handleClick = (color: any) => {
    setIsActive((prev: any) => {
      if (prev[color]) return { ...prev, [color]: false };
      return { ...prev, [color]: true };
    });
  };

  return { tags_static, tags, addTag_deleteTag, matchTags, isActive, setIsActive, handleClick };
};
