import React from 'react';
import { Button, Grid, Column } from '@carbon/react';
import { Email } from '@carbon/icons-react';
import './MvpFooter.scss';

const Footer: React.FC = () => {
  const handleCallToAction = () => {
    window.location.href = 'mailto:matthias.biniok@de.ibm.com';
  };

  return (
    <>
      <div className="footer-bar">
        <div className="footer">
          <Grid>
            <Column lg={2} md={1} sm={0}></Column>
            <Column lg={4} md={2} sm={4}>
              <div className="client-engineering">
                Das ist <br /> <span className="blue-ce"> IBM Client Engineering</span>
              </div>
            </Column>
            <Column lg={4} md={2} sm={4}>
              <div className="explainer-ce">
                IBM Client Engineering ist ein Angebot der IBM an ihre Kunden, um mit Ihnen Ihre
                Digitalisierungs-
                <br />
                herausforderungen in gemeinsamer Zusammenarbeit anzugehen.
                <br />
                <br />
                Wir führen Sie in wenigen Wochen von einem vagen Problem zu einer initialen,
                praktikablen Lösung.
              </div>
            </Column>
            <Column lg={4} md={2} sm={4}>
              <div className="call-to-action-ce">
                <span className="text">
                  Dies ist eine Investition der IBM. Was wir von unseren Kunden brauchen, ist
                  Engagement und Wissen.
                </span>
                <Button className="button" onClick={handleCallToAction} renderIcon={Email}>
                  Nehmen Sie gerne mit uns Kontakt auf
                </Button>
              </div>
            </Column>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Footer;
