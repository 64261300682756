import { UserAvatar } from '@carbon/react/icons';
import { Link } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from '@carbon/react';
import { MvpRoute } from '../../types/MvpRoute';

import { useLocation } from 'react-router-dom';

interface MvpHeaderProps {
  routes: MvpRoute[];
  prefix: string;
  title: string;
  setCurrentTheme: any;
}

const MvpHeader = ({ routes, prefix, title }: MvpHeaderProps) => {

  const location = useLocation();

  if (location.pathname === '/') {
    return null;
  }

  return (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }: any) => (
      <Header aria-label="MVP">
        <SkipToContent />
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName element={Link} to="/" prefix={prefix}>
          {title}
        </HeaderName>
        <HeaderNavigation aria-label="">
          {routes.map((route: MvpRoute, index) => {
            return route.isStartPage ? null : (
              <HeaderMenuItem element={Link} to={route.path} key={index}>
                {route.label}
              </HeaderMenuItem>
            );
          })}
        </HeaderNavigation>
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
          <SideNavItems>
            <HeaderSideNavItems>
              {routes.map((route: MvpRoute, index) => {
                return route.isStartPage ? null : (
                  <HeaderMenuItem element={Link} to={route.path} key={index}>
                    {route.label}
                  </HeaderMenuItem>
                );
              })}
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
        <HeaderGlobalBar>
          <HeaderGlobalAction aria-label="User Avatar" tooltipAlignment="end">
            <UserAvatar size={20} />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
      </Header>
    )}
  />
)};

export default MvpHeader;
