import Fuse from 'fuse.js';
import { useState } from 'react';
import projects_data from '../../data/Projects.json';

const demos = projects_data.slice(0, 4);
const kuerzeverfuegbar = projects_data.slice(4, 8);

const SearchHandler = () => {
  const [searchData, setSearchData] = useState(demos);
  const [searchData2, setSearchData2] = useState(kuerzeverfuegbar);

  const searchItem = (query: any, setSearchDataFn: any, data: any, keys: any) => {
    if (!query) {
      setSearchDataFn(data);
      return;
    }

    const fuse = new Fuse(data, {
      keys: keys,
    });

    const result = fuse.search(query);
    const finalResult: any = [];

    if (result.length) {
      result.forEach((project) => {
        finalResult.push(project.item);
      });
      setSearchDataFn(finalResult);
    } else {
      setSearchDataFn([]);
    }
  };

  return {
    searchData,
    searchData2,
    searchItem: (query: any) =>
      searchItem(query, setSearchData, demos, [
        'title',
        'description',
        'tags',
        'tags1',
        'email',
        'password',
        'demo',
        'link',
      ]),
    searchItem2: (query: any) =>
      searchItem(query, setSearchData2, kuerzeverfuegbar, ['title', 'description']),
  };
};

export default SearchHandler;
