import MvpHeader from '../components/MvpHeader/MvpHeader';
import MvpFooter from '../components/MvpFooter/MvpFooter';
import './Layout.scss';
import { MvpRoute } from '../types/MvpRoute';

interface LayoutProps {
  children: React.ReactNode;
  routes: MvpRoute[];
  setCurrentTheme: any;
  appPrefix: string;
  appTitle: string;
}

export default function Layout({
  children,
  routes,
  setCurrentTheme,
  appPrefix,
  appTitle,
}: LayoutProps) {
  return (
    <>
      <MvpHeader
        routes={routes}
        prefix={appPrefix}
        title={appTitle}
        setCurrentTheme={setCurrentTheme}
      />
      <div className="bodyWrapper">
        <div className="componentSpace">{children}<MvpFooter/></div>
        
      </div>
    </>
  );
}
